header {
    padding: 1.5rem;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-items: center;
    justify-content: space-between;
    align-items: center;
    background-color: cadetblue;
    z-index: 2;
}

.header-text {
    color: blue;
    margin: 0;
}

#social-media-container {
    display: flex;
    justify-content: space-around;
}

#fb-icon {
    color:blue;
}

#ig-icon {
    color: blue;
}

/* Popout Menu */

.menu-title-container {
    padding: 0.6rem;
    background-color: #6A7D8A;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.nav-menu-container-active {
    width: 50%;
    height: 100vh;
    position: fixed;
    top: 0vh;
    left: 0vw;
    padding-left: 0;
    margin-top: 0;
    background-color: #93AEBF;
    z-index: 2;
    transform: translate3d(0, 0, 0);
    transition: transform 1s;
}

.nav-menu-container-inactive {
    width: 50%;
    height: 100%;
    position: fixed;
    top: 0vh;
    left: 0vw;
    padding-left: 0;
    margin-top: 0;
    
    background-color: #93AEBF;
    z-index: 0;
    transform: translate3d(-100%, 0, 0);
    transition: transform 1s;
    overflow: hidden;
}

.header-menu-button-active {
    position: absolute;
    left: 8%;
    cursor: pointer;
}


.menu-list {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 5vh;
    padding-top: 5vh;
    list-style-type: none;
    font-size: 1.5rem;
    width: 100%;
    text-align: center;
}

.blackout-active {
    height: 100vh;
    width: 100vw;
    background-color: black;
    position: absolute;
    right: -100vw;
    top: 0;
    opacity: 60%;
}

.blackout-inactive {
    display: none;
}

#menu-purchase {
    border: 1px solid black;
    border-radius: 8px;
    padding: 0.25rem;
    text-align: center;
    width: 80%
}

#menu-logo {
    width: 45%;
    box-sizing: border-box;
    
}

.menu-footer {
    position: absolute;
    width:100%;
    text-align: center;
    bottom: 0.5rem;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    
}

@media only screen and (min-width: 768px) {
    .header-menu-button-inactive {
        cursor: pointer;
    }
    .nav-menu-container-active {
        width: 33%;
    }

    .nav-menu-container-inactive {
        width: 33%;
    }

    .menu-list {
        row-gap: 6vh;
    }

    .menu-item:hover {
        transform: scale(1.5);
        transition-duration: 0.5s;
        cursor: pointer;
        color: grey;
    }

    .menu-item:active {
        color:white;
    }

    #menu-purchase:hover {
        transform: scale(1.1);
        transition-duration: 0.5s;
        background-color: #74A3B3;
        color: rgb(191, 239, 255);
        border: rgb(191, 239, 255);
        cursor: pointer;
    }

    #menu-purchase:active {
        color:white;
    }

    .menu-footer {
        margin-bottom: 1rem;
    }
    #menu-logo {
        width: 20%;
        box-sizing: border-box;
    }


}