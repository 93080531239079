#vendor-main {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;
    min-height: 90%;
    width: 90%;
    padding: 0.75rem 1.25rem;
    position: fixed;
    z-index: 5;
    background-color: white;
    border: 3px solid black;
    overflow: scroll;
}

#hide-vendor-main {
    display: none;
}

.close-vendor-menu-button {
    width: fit-content;
    position: absolute;
    right: 2rem;
}

#vendor-title {
    display: flex;
    justify-content: center;
}

.table-info {
    padding-left: 40px;
}

#vendor-disclaimer {
    display: flex;
    text-align: center;
    justify-content: center;
}

@media only screen and (min-width: 768px) {
    .close-vendor-menu-button:hover {
        cursor: pointer;
        background-color: lightgrey;
        border-radius: 15px;
    }
}