.footer-container {
    padding: 1.5rem 1.5rem;
}

.footer-contact-container {
    display: flex;
    flex-direction: column;
}

.footer-title {
    margin: 0.5rem;
}

.footer-info {
    margin: 0.5rem;
}