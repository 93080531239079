.upcoming-container {
    max-width: 100%;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

#upcoming-container-title {
    text-align: center;
}

.upcoming-event-title {
    text-align: center;
}

.upcoming-event-title-info {
    margin: 0;
}

.upcoming-event-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border: 0.25rem black solid;
    padding: 0.25rem;
    margin-bottom: 0.5rem;
}
.upcoming-event-banner-container {
    display: flex;
    justify-content: center;
    color:white;
    width: 100%;
    height:20rem;
    margin-bottom: 2rem;
}

.upcoming-image {
    width: 80%;
}

#upcoming-event1-banner-container {
    background-image: url('../../resources/sportcard.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
}

#upcoming-event2-banner-container {
    background-image: url('../../resources/ian.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: 100% 100%;
}



@media only screen and (min-width: 768px) {
    .upcoming-container {
        max-width: 100%;
        padding-left: 15rem;
        padding-right: 15rem;
    }
}