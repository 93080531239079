.about-container {
    padding: 3rem 1.5rem;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.07);
}

@media only screen and (min-width: 768px) { 
    .about-container {
        padding: 3rem 15rem;
    }
}