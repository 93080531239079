.contact-container {
    padding: 3rem 1.5rem;
    width: auto;
}

.contact-form {
    width: 100%
}

label {
    width: 100%;
    display: block;
    box-sizing: border-box;
    margin-top: 1rem;
}

input {
    width: 100%;
    display: block;
    box-sizing: border-box;
}

textarea {
    width: 100%;
    display: block;
    box-sizing: border-box;
}

.submit-container {
    display: flex;
    flex-direction: row-reverse;
    margin-top: 1rem;
}

#submit-button {
    padding: 0.625rem 1rem;
    margin-top: 1rem;
    background-color: rgb(191, 239, 255);
    border-color: rgb(191, 239, 255);
    border-width: 2px;
    border-style: solid;
    box-shadow: none;
    border-radius: 0.375rem;
    width: 45%;
    
}

@media only screen and (min-width: 768px) { 
    .contact-container {
        padding: 3rem 15rem;
        width: auto;
    }

    #submit-button {
        width: 25%;
    }

    #submit-button:hover {
        transition-duration: 0.5s;
        background-color: #74A3B3;
        color: rgb(191, 239, 255);
        cursor: pointer;
    }
}
